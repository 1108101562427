import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import {
  shouldBeSelected,
  shouldRenderFavoriteUpdateButton,
  ACTIONS,
  getFavoriteNameByUid,
  getFavoriteDefaultFlagByUid,
  updateSelectedFavoriteFilters
} from '../util/favoritesHelper';
import { isQueryStringEmpty } from '../../../templates/util/api/queryStringManager';
import Icon from '../../../atoms/icons/Icon';
import Button from '../../../atoms/buttons/Button';
import Anchor from '../../../atoms/anchors/Anchor';
import { getFavoriteRequest } from '../../../templates/invoices/util/api/request';
import { isOnSuppliersTab } from '../../../templates/helper/documentsTabHelper.js';
import { getDateStringDependingOnLabel } from '../../../molecules/forms/util/dateIntervalHelper';

/**
 * React component
 * @class
 * Dropdown for user favorites search.
 */
class FavoriteDefault extends Component {

  constructor(props) {
    super(props);

    const { customFavorites, defaultFavorites, favoriteUID, accountId } = this.props;

    this.state = {
      customFavorites,
      favoriteUID,
      accountId,
      favoriteName: getFavoriteNameByUid(customFavorites, favoriteUID),
      defaultSelected: getFavoriteDefaultFlagByUid(defaultFavorites, favoriteUID)
    };

    this.applyFilter = this.applyFilter.bind(this);
  }

  /**
   * Request filter for given Favorite UID
   * Keeps track of current Favorite UID on state
   * Calls props to update filters
   * @param {string} uid - Favorite UID to keep track and get filters
   * @param {string} accountId - account id.
   * @param {string} name - Favorite Name to keep track
   * @param {boolean} isDefaultFavorite - indicates if Favorite is a default favorite
   */
  async applyFilter(uid, accountId, name, isDefaultFavorite) {
    const response = await getFavoriteRequest(uid, accountId);
    let favoriteFilter = await response.json();
    this.setState({ favoriteName: name, defaultSelected: isDefaultFavorite });

    favoriteFilter = this.setDateDependingOnLabel(favoriteFilter);

    const { filters } = favoriteFilter;

    this.props.updateAndApplyFilter(filters, uid);
    this.props.updateFavoriteUID(uid);
    this.props.closeFavoriteSearch();
  }

  /**
   * Set the proper date values for filters considering the date label
   * provided by the stored favorite
   * @param {object} favoriteFilter - Favorite filter returned by storage
   */
  setDateDependingOnLabel(favoriteFilter) {
    if (favoriteFilter.filters.issueDateLabel) {
      favoriteFilter.filters.issueDate = getDateStringDependingOnLabel(
        favoriteFilter.filters.issueDateLabel,
        favoriteFilter.filters.issueDate
      );
    }

    if (favoriteFilter.filters.dueDateLabel) {
      favoriteFilter.filters.dueDate = getDateStringDependingOnLabel(
        favoriteFilter.filters.dueDateLabel,
        favoriteFilter.filters.dueDate
      );
    }
    return favoriteFilter;
  }

  /**
   * Renders default Favorites
   * @function
   * @returns list of components to render
   */
  renderDefaultFavorites = () => {
    let childComponents = [];
    const accountId = this.props.accountId;
    this.props.defaultFavorites.map(({ name, uid, defaultFlag }) => {
      return (
        childComponents.push(
          <li
            key={uid}
            onClick={() => (this.applyFilter(uid, accountId, name, defaultFlag))}
          >
            <Anchor
              className={shouldBeSelected(uid, this.state.favoriteUID)}
            >
              <FormattedMessage id={name} />
            </Anchor>
          </li>
        )
      );
    });
    return childComponents;
  };

  /**
   * Renders Users custom Favorites
   * @function
   * @returns list of components to render
   */
  renderCustomFavorites = () => {
    const { updateAction } = this.props;
    let childComponents = [];
    const customFavorites = this.state.customFavorites;
    const accountId = this.props.accountId;
    customFavorites.map(({ name, uid, defaultFlag }) => {
      return (
        childComponents.push(
          <li key={uid} className={shouldBeSelected(uid, this.state.favoriteUID)}>
            <div className='title-icon row align-items-center'>
              <div className='col-8 bold' onClick={() => (this.applyFilter(uid, accountId, name, defaultFlag))}>
                {name}
              </div>
              <div className='col-4 text-align-right'>
                <Anchor onClick={() => (updateAction(ACTIONS.updateName, { name, uid }))}>
                  <Icon className='icon fas fa-pencil-alt' />
                </Anchor>
                <Anchor onClick={() => (updateAction(ACTIONS.delete, { name, uid }))}>
                  <Icon className='icon far fa-trash-alt' />
                </Anchor>
              </div>
            </div>
          </li>
        ));
    });
    return childComponents;
  };

  /**
   * React life cycle method
   * @param {object} prevProp - Component props before updating
   * @function
   */
  componentDidUpdate(prevProp) {
    //sets custom favorites when they are loaded and state is empty
    if (prevProp.customFavorites.length !== this.props.customFavorites.length) {
      this.setState({ customFavorites: this.props.customFavorites });
    }

    //updates current UID
    if (this.props.favoriteUID !== this.state.favoriteUID) {
      this.setState({ favoriteUID: this.props.favoriteUID });
    }
  }

  render() {
    const {
      updateAction,
      customFavorites,
      filterUpdatedFlag,
      filters,
      accountId,
      userId,
      tab,
      updateFavoriteFlag,
    } = this.props;

    const { favoriteUID, favoriteName, defaultSelected } = this.state;
    const renderSeparator = customFavorites.length > 0;

    return (
      <Fragment>
        {!isOnSuppliersTab(tab) &&
          <Fragment>
            <div className='fav-options'>
              <Button
                className='button button-simple-icon --big'
                label='saveNewFavorite'
                disabled={isQueryStringEmpty()}
                onClick={() => (updateAction(ACTIONS.add))}
              >
                <Icon className='icon fas fa-plus' />
              </Button>
              {shouldRenderFavoriteUpdateButton(favoriteUID, customFavorites, defaultSelected) && (
                <Button
                  className='button button-simple-icon --big'
                  disabled={!filterUpdatedFlag}
                  onClick={() =>
                    updateSelectedFavoriteFilters(
                      favoriteName,
                      favoriteUID,
                      filters,
                      accountId,
                      userId,
                      tab,
                      updateFavoriteFlag
                    )}
                >
                  <Icon className='icon fas fa-sync-alt' />
                  <FormattedMessage
                    id='editFavorite'
                    values={{ favoriteName }}
                  />
                </Button>
              )}
            </div>
            <div className='sep'></div>
          </Fragment>
        }
        {/* Defult Favorites */}
        <ul className='fav-defult bold'>
          {this.renderDefaultFavorites()}
        </ul>
        {renderSeparator && (
          <Fragment>
            <div className='sep'></div>
            {/* Editable Favorites */}
            <ul className='scroll'>
              {this.renderCustomFavorites()}
            </ul>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default injectIntl(FavoriteDefault);

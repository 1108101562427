import RequestAdapter from '../adapters/RequestAdapter';
import { API_V3_PATH_BASE, V2_PATH_BASE } from '../constants';
import { ENDPOINTS } from '../enums/endpoints';

/**
 * Fetch a list of plugins
 *
 * @function
 * @returns {object} JSON response with the plugins listing
 */
const getAll = () => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.PLUGINS.DEFAULT}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Fetch a list of plugins by account
 *
 * @function
 * @returns {object} JSON response with the plugins listing active
 */
const getListByAccount = () => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.PLUGINS.BY_ACCOUNT}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Featch a list of plugins integrations active/inactive
 * @returns {object} JSON response with the plugins integrations
 */
const getAllIntegrations = () => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.PLUGIN_INTEGRATIONS.DEFAULT}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Featch a integration by id
 * @param {string} id - integration id
 * @returns {object} JSON response with the plugins integrations
 */
const getIntegrationById = (id) => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.PLUGIN_INTEGRATIONS.DEFAULT}/${id}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Update plugins integration
 * @param {string} id - integration id
 * @returns {object} JSON response with the plugins integrations
 */
const updateIntegrationStatus = (id) => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.PLUGIN_INTEGRATIONS.STATUS(id)}`,
    method: 'PUT',
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Register a plugin integrations
 * @param {string} id - plugin id
 * @param {object} form
 * @returns {void}
 */
const registerAnIntegration = (id, form) => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.PLUGIN_INTEGRATIONS.DEFAULT}`,
    method: 'POST',
    body: JSON.stringify({
      ...form,
      plugin_id: id,
    }),
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Update a plugin integration
 * @param {string} id - integration id
 * @param {object} form
 * @returns {void}
 */
const updateIntegrationById = (id, form) => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.PLUGIN_INTEGRATIONS.DEFAULT}/${id}`,
    method: 'PUT',
    body: JSON.stringify({
      ...form,
      plugin_id: id,
    }),
  };

  return RequestAdapter.request(requestConfig);
};

export const PluginService = {
  getAll,
  getListByAccount,
  getAllIntegrations,
  getIntegrationById,
  updateIntegrationStatus,
  registerAnIntegration,
  updateIntegrationById,
};

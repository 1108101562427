/* eslint no-console: ["error", { allow: ["error"] }] */

import {
  V2_PATH_BASE,
  ACCOUNT_THRESHOLD,
  EXCEPT_ID_THRESHOLD,
  INVOICES_BULK_ACTIONS_PATH,
  ESTIMATES_BULK_ACTIONS_PATH,
  GUIDES_BULK_ACTIONS_PATH,
  CHECK_INVOICES_WITHOUT_EMAIL_PATH,
  CHECK_ESTIMATES_WITHOUT_EMAIL_PATH,
  CHECK_GUIDES_WITHOUT_EMAIL_PATH,
  API_V3_PATH_BASE,
} from '../../../../../constants/index';
import { buildPath } from '../../../../organisms/containers/util/pathHelper';
import { getTotalFilter } from '../../../util/api/queryStringHelper';
import { capitalize } from '../../../../../formatters/string';
import { BULK_SYNC_LIMIT, V3_PATH_BASE } from '../../../../../constants';
import {
  isOnGuidesTab,
  isOnBillingsTab,
} from '../../../helper/documentsTabHelper';
import { allInvoicesTypes } from '../filters/allFilters';

/**
 * Assesses whether the received response is in the JSON format or not.
 *
 * @function
 *
 * @param {object} responseHeader - the Header of the request's response.
 */
const isResponseJSON = (responseHeader) => {
  return responseHeader.get('Content-Type').indexOf('application/json') > -1;
};

/**
 * Responsible to handle the HTTP Request to the requestPath, using the method and fetchFunction
 * passed as arguments
 *
 * @function
 *
 * @param {object} fetchFunction - function responsible for making the request itself.
 * @param {string} method - whether it is a POST or GET request (only supports GET request at the moment).
 * @param {string} requestPath - specific path to the mapped route on the server side, either V2 or V3 environment.
 *
 * @returns {object} a JSON if the response is in the JSON format.
 * @returns {Promise} in case of any error.
 */
export const httpRequest = (fetchFunction, method, requestPath) => {
  // Configuration to accept JSON as a default
  const config = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetchFunction(requestPath, config).then((response) => {
    // Check if the request's response is 200 (OK)
    if (response.ok) {
      // If it's a JSON response returns JSON, else returns the normal response
      return isResponseJSON(response.headers) ? response.json() : response;
    }

    // If any errors, reject actual response
    return Promise.reject(response);
  });
};

/**
 * Builds the path to where the HTTP Request will be made
 * @function
 *
 * @param {string} newPagePath - represents the path to the request function.
 */
export const buildV2Path = (newPagePath) => {
  const isLocalhost = window.location.host.includes('localhost');

  return isLocalhost ? `${V2_PATH_BASE}${newPagePath}` : buildPath(newPagePath);
};

/**
 * Builds  query string parameter related to page.
 * @function
 * @param {object} filters - JSON with all filters.
 * @returns {string} query string parameter.
 */
const getPageFilter = (filters) => {
  return `page=${filters.page}`;
};

/**
 * Builds  query string parameter related to page.
 * @function
 * @param {object} filters - JSON with all filters.
 * @returns {string} query string parameter.
 */
const getStatusFilter = (filters) => {
  let query = '&';
  Object.keys(filters.status).forEach((status) => {
    if (filters.status[status]) {
      query += `status[]=${status}&type[]=${allInvoicesTypes[0]}&type[]=${allInvoicesTypes[2]}&`;
    }
  });

  return query.slice(0, -1);
};

/**
 * Builds  query string parameter related to items per page.
 * @function
 * @param {object} filters - JSON with all filters.
 * @returns {string} query string parameter.
 */
const getItemsPerPageFilter = (filters) => {
  return `items_per_page=${filters.itemsPerPage}`;
};

/**
 * Builds  query string parameter related to accound id.
 * @function
 * @param {number} accountId - Account ID.
 * @returns {string} query string parameter.
 */
const getAccountIdFilter = (accountId) => {
  return `account_id=${accountId}`;
};

/**
 * Builds  query string parameter related to language.
 * @function
 * @param {string} language - Account ID.
 * @returns {string} query string parameter.
 */
const getLanguageFilter = (language) => {
  return `language=${language}`;
};

/**
 * Extracts active keys from a JSON object
 * @function
 * @param {object} filters - JSON with all filters.
 * @param {boolean} upperCase - if the keys should be formatted or not.
 * @returns {object} array object with all active keys
 */
const activeKeys = (filters, upperCase = false) => {
  const keys = [];
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      if (upperCase) {
        keys.push(capitalize(key));
      } else {
        keys.push(key);
      }
    }
  });

  return keys;
};

/**
 * Builds query string to request documents considering the filters
 * @function
 * @param {number} accountId - Account ID.
 * @param {string} language - Account language.
 * @param {object} filters - JSON with all filters.
 * @param {object} windowLocation - window location property.
 * @returns {string} complete query string for API request
 */
export const buildDocumentsRequestParams = (
  accountId,
  language,
  filters,
  windowLocation,
  tab = ''
) => {
  let queryString = '';
  queryString += `${getAccountIdFilter(accountId)}&`; // Always
  const search = windowLocation.search;
  const params = new URLSearchParams(search);
  const currentQueryString = decodeURIComponent(search);

  if (currentQueryString) {
    if (params.get('language') === null) {
      queryString += `${getLanguageFilter(language)}&`;
    }

    if (params.get('items_per_page') === null) {
      queryString += `${getItemsPerPageFilter(filters)}&`;
    }

    if (params.get('page') === null) {
      queryString += `${getPageFilter(filters)}&`;
    }

    if (isOnBillingsTab(tab)) {
      queryString = queryString.slice(0, -1);
      queryString += `${getStatusFilter(filters)}`;
    }

    queryString += params.toString().replace('?', '');
  } else {
    queryString += `${getLanguageFilter(language)}&`;
    queryString += `${getItemsPerPageFilter(filters)}&`;
    queryString += `${getPageFilter(filters)}&`;

    queryString = queryString.slice(0, -1);

    if (isOnBillingsTab(tab)) {
      queryString += `${getStatusFilter(filters)}`;
    }
  }

  return queryString;
};

/**
 * Adds only params with content to the request body
 * @function
 * @param {object} body - request body.
 * @param {string} key - JSON key.
 * @param {object} values - JSON object.
 * @returns {object} body for API request
 */
const addRangeValuesIfValid = (body, key, values) => {
  if (values.from || values.to) {
    body[key] = values;
  }
};

/**
 * Adds specific date ranges based on the users tab.
 *
 * @function
 * @param {object} body - request body.
 * @param {object} filters - JSON with all filters.
 * @param {string} documentsTab - current users Tab.
 */
const addSpecificDateRange = (body, filters, documentsTab) => {
  let dateKey = 'due_date';
  let dateValue = filters.dueDate;

  if (documentsTab === 'Estimates') {
    dateKey = 'valid_to';
    dateValue = filters.validTo;
  }

  addRangeValuesIfValid(body, dateKey, dateValue);
};

/**
 * Adds specific issue date ranges based on the users tab.
 *
 * @function
 * @param {object} body - request body.
 * @param {object} filters - JSON with all filters.
 * @param {string} documentsTab - current users Tab.
 */
const addSpecificIssueDateRange = (body, filters, documentsTab) => {
  let dateKey = 'date';
  let dateValue = filters.issueDate;

  if (isOnGuidesTab(documentsTab)) {
    dateKey = 'loaded_at';
    dateValue = filters.loadedAt;
  }

  addRangeValuesIfValid(body, dateKey, dateValue);
};

/**
 * Parametrizes body considering the applied filters
 * @function
 * @param {object} body - request body.
 * @param {object} filters - JSON with all filters.
 * @param {string} documentsTab - current users Tab.
 * @returns {object} body for API request
 */
const createBodyFromAppliedFilters = (
  body,
  filters,
  documentsTab = 'Invoices'
) => {
  const totalsKey = getTotalFilter(filters.showTotalWithIVA);

  body['page'] = filters.page;
  body['items_per_page'] = filters.itemsPerPage;
  body['text'] = filters.text;
  body['type'] = activeKeys(filters.type, true);
  body['status'] = activeKeys(filters.status);
  body['archived'] = `${filters.archived}`;
  body['non_archived'] = `${filters.nonArchived}`;
  body['serie_name'] = filters.series;
  body['plugin'] = filters.plugins;
  body['client_id'] = filters.clientList;
  body['sort'] = filters.sort;
  body['sort_order'] = filters.sortOrder;
  body['batch_id'] = filters.batchId;

  addRangeValuesIfValid(body, totalsKey, filters.documentTotalRange);
  addSpecificIssueDateRange(body, filters, documentsTab);

  if (!isOnGuidesTab(documentsTab)) {
    addSpecificDateRange(body, filters, documentsTab);
  }
};

/**
 * Parametrizes body considering the text filter
 *
 * @function
 *
 * @param {object} body - request body.
 * @param {object} filters - JSON with all filters.
 *
 * @returns {object} body for simple API request
 */
const createBodyFromTextFilter = (body, filters) => {
  body['page'] = filters.page;
  body['items_per_page'] = filters.itemsPerPage;
  body['text'] = filters.text;
  body['sort'] = filters.sort;
  body['sort_order'] = filters.sortOrder;
};

/**
 * Builds the id parameter on the request body.
 * @function
 * @param {object} requestBody - request's body.
 * @param {object} documentIds - documentIds - array with ids selected.
 * @param {boolean} except - boolean that represents wether there should be sent except_ids or not
 */
export const buildBodyDocumentIds = (requestBody, documentsIds, except) => {
  const parameterId = except ? 'except_id' : 'id';
  requestBody[parameterId] = [];

  documentsIds.forEach((id) => {
    requestBody[parameterId].push(id);
  });
};

/**
 * Checks whether the request body needs to be sent with the search parameters
 *
 * @function
 * @param {object} documentsInformation - contains all the information regarding the documents - selected/unselected IDs,
 * checked/unchecked of the 'Select All' checkbox and total number of documents.
 * @returns {boolean} whether the search parameters should be sent or not.
 */
const shouldSendWithSearchParams = (documentsInformation) => {
  return (
    documentsInformation.documentsSelected.size <= 0 ||
    documentsInformation.allDocumentsSelected ||
    documentsInformation.prevAllDocumentsSelected
  );
};

/**
 * Checks if there are any documents selected, without selecting all the documents.
 *
 * @function
 * @param {object} documentsInformation - contains all the information regarding the documents - selected/unselected IDs,
 * checked/unchecked of the 'Select All' checkbox and total number of documents.
 * @returns {boolean} whether there are documents selected or not.
 */
const someDocumentSelected = (documentsInformation) => {
  return (
    documentsInformation.documentsSelected.size > 0 &&
    !documentsInformation.allDocumentsSelected
  );
};

/**
 * Checks if there are any documents deselected, after having all documents selected.
 *
 * @function
 * @param {object} documentsInformation - contains all the information regarding the documents - selected/unselected IDs,
 * checked/unchecked of the 'Select All' checkbox and total number of documents.
 * @returns {boolean} whether there are documents deselected or not.
 */
const someDocumentsDeselected = (documentsInformation) => {
  return (
    documentsInformation.documentsDeselected.size > 0 &&
    documentsInformation.prevAllDocumentsSelected
  );
};

/**
 * Builds the path for bulk actions, based on the current tab.
 * @param {String} documentsTab - Current selected tab.
 * @returns {String} bulk request path.
 */
export const buildBulkActionsPath = (documentsTab, bulkActionId) => {
  const bulkPath = {
    Invoices: INVOICES_BULK_ACTIONS_PATH[bulkActionId],
    Estimates: ESTIMATES_BULK_ACTIONS_PATH[bulkActionId],
    Guides: GUIDES_BULK_ACTIONS_PATH[bulkActionId],
  }[documentsTab];

  return `${V3_PATH_BASE}${bulkPath}`;
};

/**
 * Builds the path for bulk actions, based on the current tab.
 * @param {String} documentsTab - Current selected tab.
 * @returns {String} bulk request path.
 */
export const buildAPIBulkActionsPath = (documentsTab, bulkActionId) => {
  const bulkPath = {
    Invoices: INVOICES_BULK_ACTIONS_PATH[bulkActionId]
  }[documentsTab];

  return `${API_V3_PATH_BASE}${bulkPath}`;
};

/**
 * Builds the path for bulk email, based on the current tab.
 * @param {String} documentsTab - Current selected tab.
 * @returns {String} bulk email request path.
 */
export const buildBulkEmailPath = (documentsTab) => {
  const checkDocumentsPath = {
    Invoices: CHECK_INVOICES_WITHOUT_EMAIL_PATH,
    Estimates: CHECK_ESTIMATES_WITHOUT_EMAIL_PATH,
    Guides: CHECK_GUIDES_WITHOUT_EMAIL_PATH,
  }[documentsTab];

  return `${V3_PATH_BASE}${checkDocumentsPath}`;
};

/**
 * Checks if the volume of the account (in documents) surpasses the threshold defined.
 *
 * @function
 * @param {object} documentsInformation - contains all the information regarding the documents - selected/unselected IDs,
 * checked/unchecked of the 'Select All' checkbox and total number of documents.
 * @returns {boolean} whether that threshold is surpassed or not.
 */
const isAccountBig = (documentsInformation) => {
  return documentsInformation.numberOfDocuments >= ACCOUNT_THRESHOLD;
};

/**
 * Checks if there are too many deselected documents, surpassing then the threshold defined.
 *
 * @function
 * @param {object} documentsInformation - contains all the information regarding the documents - selected/unselected IDs,
 * checked/unchecked of the 'Select All' checkbox and total number of documents.
 * @returns {boolean} whether there are too many unselected documents or not.
 */
const tooManyUnselectedDocuments = (documentsInformation) => {
  return (
    documentsInformation.documentsDeselected.size >=
    documentsInformation.numberOfDocuments * EXCEPT_ID_THRESHOLD
  );
};

/**
 * Checks whether the request body needs to be sent with the 'id' parameter or not.
 *
 * @function
 * @param {object} documentsInformation - contains all the information regarding the documents - selected/unselected IDs,
 * checked/unchecked of the 'Select All' checkbox and total number of documents.
 * @returns {boolean} whether the parameter should be sent or not.
 */
const shouldSendWithIDs = (documentsInformation) => {
  if (shouldSendWithSearchParams(documentsInformation)) return false;

  if (
    !isAccountBig(documentsInformation) &&
    !documentsInformation.allDocumentsSelected
  )
    return true;

  if (
    isAccountBig(documentsInformation) &&
    tooManyUnselectedDocuments(documentsInformation)
  )
    return true;

  return someDocumentSelected(documentsInformation);
};

/**
 * Checks whether the request body needs to be sent with the 'except_id' parameter or not.
 *
 * @function
 * @param {object} documentsInformation - contains all the information regarding the documents - selected/unselected IDs,
 * checked/unchecked of the 'Select All' checkbox and total number of documents.
 * @returns {boolean} whether the parameter should be sent or not.
 */
const shouldSendWithExceptIDs = (documentsInformation) => {
  if (shouldSendWithIDs(documentsInformation)) return false;

  if (someDocumentsDeselected(documentsInformation)) return true;

  return (
    isAccountBig(documentsInformation) &&
    !tooManyUnselectedDocuments(documentsInformation)
  );
};

/**
 * Builds the request generic body, based on the amount of IDs selected or if all documents are selected.
 *
 * @function
 *
 * @param {object} body - request body.
 * @param {object} documentsInformation - JSON object with all the information regarding the document selection - set with selected/deselected
 * documents and booleans that represent if all the documents were previously selected
 * @param {object} searchInformation - JSON object with the search made by the user, as filters, selected tab, etc.
 */
const buildGenericRequestBody = (
  body,
  documentsInformation,
  searchInformation
) => {
  const { documentsSelected, documentsDeselected } = documentsInformation;
  const { filters, documentsTab } = searchInformation;

  if (shouldSendWithSearchParams(documentsInformation)) {
    createBodyFromAppliedFilters(body, filters, documentsTab);
  }

  if (shouldSendWithIDs(documentsInformation)) {
    buildBodyDocumentIds(body, documentsSelected, false);
  }

  if (shouldSendWithExceptIDs(documentsInformation)) {
    buildBodyDocumentIds(body, documentsDeselected, true);
  }
};

/**
 * Fills the request body with the direct download parameter for pdf sync bulk.
 *
 * @function
 * @param {object} body - the request body.
 */
const buildBodyDirectDownload = (requestBody) => {
  requestBody['is_direct_download'] = true;
};

/**
 * Verify if should be a sync bulk process by the bulk action and number of selected documents
 * @function
 * @param {string} bulkActionId - bulk action identifier.
 * @param {number} numberOfDocumentsSelected - calculated total number of selected documents.
 * @returns {boolean} true for synchronized bulk
 */
export const shouldBeBulkSync = (bulkActionId, numberOfDocumentsSelected) => {
  if (
    bulkActionId &&
    bulkActionId.match(/^(downloadPDF)$/g) &&
    numberOfDocumentsSelected <= BULK_SYNC_LIMIT
  ) {
    return true;
  }
  return false;
};

/**
 * Fills the request body based on the information presented on the documentsInformation.
 *
 * @function
 * @param {object} body - the request body.
 * @param {object} filters - JSON with all filters.
 * @param {object} documentsInformation - contains all the information regarding the documents - selected/unselected IDs,
 * checked/unchecked of the 'Select All' checkbox and total number of documents.
 * @param {string} bulkActionId - bulk action identifier.
 * @param {number} numberOfDocumentsSelected - calculated total number of selected documents.
 */
const buildRequestBody = (
  body,
  filters,
  documentsTab,
  documentsInformation,
  bulkActionId,
  numberOfDocumentsSelected
) => {
  const searchInformation = { filters, documentsTab };

  buildGenericRequestBody(body, documentsInformation, searchInformation);

  if (shouldBeBulkSync(bulkActionId, numberOfDocumentsSelected)) {
    buildBodyDirectDownload(body);
  }
};

/**
 * Builds request body to perform a summary update
 * @function
 * @param {number} accountId - Account ID.
 * @param {object} documentsIds - window location property.
 * @param {string} language - Account language.
 * @param {object} filters - JSON with all filters.
 * @param {object} documentsInformation - contains all the information regarding the documents - selected/unselected IDs,
 * checked/unchecked of the 'Select All' checkbox and total number of documents.
 * @returns {string} body for API request
 */
export const buildSummaryRequestBody = (
  accountId,
  language,
  filters,
  documentsInformation,
  documentsTab
) => {
  const body = {
    account_id: accountId, // Always
    language: language,
  };
  const searchInformation = { filters, documentsTab };

  buildGenericRequestBody(body, documentsInformation, searchInformation);

  return JSON.stringify(body);
};

/**
 * Builds request body to perform a selection info update
 *
 * @function
 *
 * @param {object} accountInformation - JSON object with all the information regarding the account - accountId, userId and language.
 * @param {object} documentsInformation - JSON object with all the information regarding the document selection - set with selected/deselected
 * documents and booleans that represent if all the documents were previously selected
 * @param {object} searchInformation - JSON object with the search made by the user, as filters, selected tab, etc.
 *
 * @returns {string} body for the BE request
 */
export const buildUpdateSelectionInfoRequestBody = (
  accountInformation,
  documentsInformation,
  searchInformation
) => {
  const body = {
    account_id: accountInformation.accountId,
    language: accountInformation.language,
  };

  buildGenericRequestBody(body, documentsInformation, searchInformation);

  return JSON.stringify(body);
};

/**
 * Builds request body to perform a summary update
 * @function
 * @param {number} accountId - Account ID.
 * @param {object} documentsIds - window location property.
 * @param {string} language - Account language.
 * @param {object} filters - JSON with all filters.
 * @param {object} documentsInformation - contains all the information regarding the documents - selected/unselected IDs,
 * checked/unchecked of the 'Select All' checkbox and total number of documents.
 * @returns {string} body for API request
 */
export const buildDocumentsPerPageBody = (
  accountId,
  language,
  filters,
  documentsInformation,
  documentsTab
) => {
  const body = {
    account_id: accountId, // Always
    language: language,
  };

  const { documentsDeselected, prevAllDocumentsSelected } =
    documentsInformation;

  createBodyFromAppliedFilters(body, filters, documentsTab);

  if (
    documentsDeselected &&
    documentsDeselected.size > 0 &&
    prevAllDocumentsSelected
  ) {
    buildBodyDocumentIds(body, documentsDeselected, true);
  }

  return JSON.stringify(body);
};

/**
 * Builds the request body to get the suppliers next page of documents
 *
 * @function
 *
 * @param {number} accountId - Account ID.
 * @param {string} language - Account language.
 * @param {object} filters - JSON with all filters.
 * @param {string} documentsTab - the tab where the user is currently on the App
 *
 * @returns {string} body for API request
 */
export const buildSuppliersPerPageBody = (
  accountId,
  language,
  filters,
  documentsTab
) => {
  const body = {
    account_id: accountId, // Always
    language: language,
  };

  createBodyFromAppliedFilters(body, filters, documentsTab);

  return JSON.stringify(body);
};

/**
 * Builds the request body to get the next page of documents, with only a text filter
 *
 * @function
 *
 * @param {number} accountId - Account ID.
 * @param {string} language - Account language.
 * @param {object} filters - JSON with all filters.
 *
 * @returns {string} body for API request
 */
export const buildSimplePerPageBody = (accountId, language, filters) => {
  const body = {
    account_id: accountId, // Always
    language: language,
  };

  createBodyFromTextFilter(body, filters);

  return JSON.stringify(body);
};

/**
 * Fills the request body for the Bulk Email process
 *
 * @function
 * @param {string} body for API request
 * @param {object} extraInformation - information regarding the Email process that does not fit anywhere else, like the email body and subject.
 */
const buildEmailBody = (body, extraInformation) => {
  if (extraInformation.testEmail) {
    body.is_test_email = true;
  }

  body.account_name = extraInformation.accountName;
  body.user_email = extraInformation.userEmail;
  body.email_body = extraInformation.emailBody;
  body.email_subject = extraInformation.emailSubject;
  body.logo_url = extraInformation.logo;
};

/**
 * Builds request body to perform a bulk action
 * @function
 * @param {object} accountInformation - JSON object with all the information regarding the account - accountId, userId and language.
 * @param {object} documentsInformation - JSON object with the Set of Documents selected and a boolean that represents if all pages are selected.
 * @param {object} filters - JSON with all filters.
 * @param {object} extraInformation - information regarding the Export CSV and Email process that does not fit anywhere else, like format type and the presence of items.
 * @param {string} bulkActionId - bulk action identifier.
 * @param {number} numberOfDocumentsSelected - calculated total number of selected documents.
 * @returns {string} body for API request
 */
export const buildBulkRequestBody = (
  documentsTab,
  accountInformation,
  documentsInformation,
  filters,
  extraInformation,
  bulkActionId,
  numberOfDocumentsSelected
) => {
  const body = {
    account_id: accountInformation.accountId,
    user_id: accountInformation.userId,
    language: accountInformation.language,
  };

  buildRequestBody(
    body,
    filters,
    documentsTab,
    documentsInformation,
    bulkActionId,
    numberOfDocumentsSelected
  );

  if (extraInformation && typeof extraInformation.formatType !== 'undefined') {
    body.format_type = extraInformation.formatType;
    body.has_items = extraInformation.hasItems;
  }

  if (extraInformation && typeof extraInformation.emailBody !== 'undefined') {
    buildEmailBody(body, extraInformation);
  }

  return JSON.stringify(body);
};

/**
 * Builds request body to create a sequence
 * @function
 * @param {string} accountId - account identifier.
 * @param {string} sequenceName - name of the serie to be created.
 * @param {boolean} setAsDefault - wether to set the serie as default or not
 * @param {boolean} createAndRegistry - Indicate if the serie is to be registered or not.
 * @returns {string} body for API request.
 */
export const buildCreateSequenceBody = (
  accountId,
  sequenceName,
  setAsDefault,
  createAndRegistry
) => {
  const body = {
    sequence: {
      serie_name: sequenceName,
      set_as_default: setAsDefault,
      create_and_registry: createAndRegistry,
    },
  };

  return JSON.stringify(body);
};

/**
 * Logs error message to the console
 * @function
 * @param {string} dataType - what the app is trying to fetch.
 * @param {object} response - HTTP response.
 */
export const logHTTPError = (dataType, response) => {
  console.error(
    `Something went wrong while trying to request ${dataType}.
    HTTP Code: ${response.statusText}.
    Response: ${response}.`
  );
};

/**
 * Logs error message to the console
 * @function
 * @param {object} error - error object.
 */
export const logError = (error) => {
  console.error(
    `Something went wrong.
    Trace: ${error}.`
  );
};
